import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TableRow, TableCell, IconButton } from "@mui/material";
import moment from "moment";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import { QuizApiService } from "../../../../services/Ananse/QuizApiService";
import { titlePage } from "utils/titlepage";
import { useTranslation } from "_metronic/i18n/language";
import { Link } from "react-router-dom";
import { FaClone } from "react-icons/fa";


const QuizList: React.FC = () => {
  const translate = useTranslation();
  const api = new QuizApiService();
  const [quiz, setQuiz] = useState<any[]>([]);
  const [reload, setReload] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const onConfirmChangeStatus = async (obj: any) => {
    if (!obj.deletedAt) {
      await api.delete(obj.quizId);
    } else {
      await api.activate(obj.quizId);
    }

    setReload(!reload);
  };

  useEffect(() => {
    titlePage(`${translate('screens.quiz.list.title')} - ${translate("labels_admin")}`);
  }, []);

  const headRows = [
    { label: translate('screens.quiz.list.actions') },
    { column: "Name", label: translate('screens.quiz.list.name') },
    {
      column: "Campaign.Name",
      label: translate('screens.quiz.list.campaign')
    },
    {
      column: "Language",
      label: translate('screens.quiz.labels.language')
    },
    {
      column: "Questions",
      label: translate('screens.quiz.list.questions')
    },
    {
      column: "Type",
      label: translate("screens_fragrance_labels_type")
    },
    {
      column: "CreatedAt",
      label: translate('screens.quiz.list.created')
    },
    {
      column: "UpdatedAt",
      label: translate('screens.quiz.list.updated')
    }
  ];

  const selectTheTypeQuiz = [
    { key: 1, name: translate("screens.quiz.labels.identification") },
    { key: 2, name: translate('labels_testType_two') },
    { key: 3, name: translate("screens.quiz.labels.discrimination") },
    { key: 4, name: translate('labels_testType_four') },
    { key: 5, name: translate("labels_ratings") },
    { key: 6, name: translate("screens_quiz_labels_identificationKid") }
  ];

  const getTypeQuiz = (type: number) => {
    const findTypequiz = selectTheTypeQuiz.find(f => f.key === Number(type));
    return findTypequiz?.name || "";
  };

  const formatRow = (r: any) => (
    <TableRow hover tabIndex={-1} key={r.quizId}>
      <TableCell>
        <CrudActions
          actions={["toggle", "edit"]}
          module="quiznew"
          onConfirmChangeStatus={onConfirmChangeStatus}
          id={r.quizId}
          obj={r}
        />
        <Link to={`/admin/quiz/${r.quizId}/clone`}>
          <IconButton
            size="small"
            // className={classes.button}
            title={translate('buttons.clone')}
          >
            <FaClone />
          </IconButton>
        </Link>
      </TableCell>
      <TableCell scope="row">{r.name}</TableCell>
      <TableCell scope="row">{r.campaign?.name}</TableCell>
      <TableCell scope="row">{r.language}</TableCell>
      <TableCell scope="row">{r.questions?.length}</TableCell>
      <TableCell scope="row">{getTypeQuiz(r.type)}</TableCell>
      <TableCell scope="row">
        {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
      </TableCell>
      <TableCell scope="row">
        {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
      </TableCell>
    </TableRow>
  );

  const filterTeste = quiz;

  return (
    <Datatable
      title={translate('screens.quiz.list.title')}
      endpoint="/quiz?Type=1"
      headRows={headRows}
      formatRow={formatRow}
      rows={filterTeste}
      setRows={setQuiz}
      reload={reload}
      placeholderSearch={translate('screens.quiz.placeholders.search')}
      isActive={isActive}
      buttons={[
        {
          label: translate('datatable.add'),
          onClick: null,
          icone: "add",
          path: "/admin/quiznew/create"
        }
      ]}
    />
  );
};

const mapStoreToProps = (store: any) => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(QuizList);
